import { SKAND_SUPPORT_EMAIL } from '@/constants/env';
import { toast } from '@skand/ui';

export const toastError = () => {
  toast({
    type: 'warn',
    message: (
      <span>
        Something went wrong while updating, please retry later or contact us at{' '}
        <a className="color-primary-400 typo-link-s" href={`mailto:${SKAND_SUPPORT_EMAIL}`}>
          {SKAND_SUPPORT_EMAIL}
        </a>
      </span>
    ),
  });
};
