import { Thumbnail } from '@/components/Thumbnail';
import { useAccountV2 } from '@/hooks/useAccountV2';
import { useEntitlements } from '@/hooks/useEntitlements';
import logger from '@/utils/logger';
import { Progress } from '@skand/ui';
import { useCreateNewSimpleUploadSession, useSimpleUploadSession } from '@skand/uploader';
import { ChangeEventHandler, MouseEventHandler, useCallback, useMemo, useRef } from 'react';
import { ChangeButton, DeleteButton } from './EntitlementButtons';
import { simpleUploadSessionRequestService, uploaderFactory } from './uploader';
import { useUpdateAccountBranding } from './useUpdateAccountBranding';
import { toastError } from './utils';

export const ChangeWebappBanner = () => {
  const { account } = useAccountV2();
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutateAsync: updateAccountBranding } = useUpdateAccountBranding();
  const { enabled: isEnabled } = useEntitlements().feature('branding') ?? {};
  const { simpleUploadSession, createNewSimpleUploadSession } = useCreateNewSimpleUploadSession(
    uploaderFactory,
    simpleUploadSessionRequestService,
  );
  const { uploadProgress, fileTreeRef, stage } = useSimpleUploadSession(simpleUploadSession);

  const accountId = account?.id;
  const webappBannerFileId = account?.branding?.webappBannerFileId;

  const isUploading = stage !== 'initial';
  const progress =
    uploadProgress.bytesTotal === 0 ? 0 : uploadProgress.bytesUploaded / uploadProgress.bytesTotal;
  const fileName = useMemo(() => {
    const fileNodes = fileTreeRef.current.mapFileNodes(fileNode => fileNode);
    const fileNode = fileNodes[0];
    if (fileNode) return fileNode.file.name;
    return '';
  }, [fileTreeRef]);

  const changeWebappBanner = async () => {
    try {
      await simpleUploadSession.start(accountId as string);

      // retrieve file id from first file
      const fileNodes = simpleUploadSession.fileTree.mapFileNodes(fileNode => fileNode);
      const fileId = fileNodes[0]?.fileId;
      if (!fileId) return;

      await updateAccountBranding({ webappBannerFileId: fileId });
    } catch (e) {
      logger.error(e);
      toastError();
    } finally {
      createNewSimpleUploadSession();
    }
  };

  const deleteWebappBanner = async () => {
    try {
      await updateAccountBranding({ webappBannerFileId: null });
    } catch (e) {
      logger.error(e);
      toastError();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const file = e.target.files?.[0];
    if (!file) return;
    e.target.value = ''; // reset files in dom

    simpleUploadSession.addFiles([file]);
    changeWebappBanner();
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const renderContent = () => {
    if (isUploading) {
      return (
        <div className="h-[160px] w-[600px] flex flex-col items-center justify-center gap-2 border border-1 border-neutral-400 rounded-md border-solid px-3">
          <p className="overflow-hidden text-ellipsis whitespace-nowrap text-center text-nowrap color-neutral-600 typo-text-small-em">
            {fileName}
          </p>
          <Progress className="w-84px" progress={progress} />
        </div>
      );
    }

    if (webappBannerFileId) {
      return <Thumbnail fileId={webappBannerFileId} height={160} width={600} />;
    }

    return (
      <div className="h-[160px] w-[600px] flex items-center justify-between border border-1 border-neutral-400 rounded-md border-solid px-3">
        <p className="color-neutral-600 underline typo-text-small-em">Choose image</p>
        <div className="i-skand-add h-[12px] color-neutral-400" />
      </div>
    );
  };

  if (!accountId) return null;
  return (
    <>
      <label className="flex items-center">
        <input
          accept="image/*"
          className="hidden"
          disabled={!isEnabled && !isUploading}
          onChange={handleChange}
          ref={inputRef}
          type="file"
        />

        {renderContent()}
      </label>

      <div className="mt-3 flex gap-3">
        <ChangeButton isDisabled={isUploading} onClick={handleClick} />
        <DeleteButton isDisabled={isUploading} onClick={deleteWebappBanner} />
      </div>
    </>
  );
};
