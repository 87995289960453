import { ConfirmationModal, ConfirmationModalState } from '@/components/ConfirmationModal';
import { AccessGate, FeatureNotIncluded } from '@/components/AccessGate';
import { FindIcon } from '@/components/IconButton';
import { SideBar } from '@/components/SideBar';
import { Table } from '@/components/Table';
import { MANAGE_TEMPLATES_CREATE } from '@/constants/paths';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { client, queryClient } from '@/graphql/client';
import {
  DELETE_ANNOTATION_TEMPLATE,
  LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID,
} from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { cn } from '@/utils/classname';
import { Button, Input } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import {
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { createColumns } from './columns';
import { SearchResultsSummary } from '@/components/SearchResultsSummary';
import { EmptyListComponent, EmptyListSeachTopic } from '@/components/EmptyListComponent';
import { handleSearchInputField } from '@/utils/misc';

export const TemplatesPage = () => {
  const {
    templates,
    response: { isFetching },
  } = useAnnotationTemplates();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [confirmationModalState, setConfirmationModalState] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const deleteAnnotationTemplate = useMutation({
    mutationFn: (annotationTemplateId: string) =>
      request(DELETE_ANNOTATION_TEMPLATE, {
        annotationTemplateId,
      }),
    onSuccess: () => {
      client.refetchQueries({
        include: [LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID],
      });
      queryClient.invalidateQueries(useAnnotationTemplates.queryKey);
    },
  });

  const handleDeleteAnnotationTemplate = useCallback(
    (id: string) => {
      setConfirmationModalState({
        isOpen: true,
        title: 'Delete Template',
        description:
          'This action cannot be undone.  Are you sure you want to delete this template?',
        actionButton: 'Delete',
        actionFunction: () => {
          deleteAnnotationTemplate.mutate(id);
          setConfirmationModalState(state => {
            return { ...state, isOpen: false };
          });
        },
      });
    },
    [deleteAnnotationTemplate],
  );

  const columns = createColumns({ handleDeleteAnnotationTemplate });

  const table = useReactTable({
    columns,
    data: templates,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    onSortingChange: setSorting,
    state: { globalFilter, sorting },
  });

  const createTemplate = (
    <AccessGate
      disabled={() => (
        <FeatureNotIncluded
          button={
            <Button className="min-w-[134px]" disabled filled primary size="s">
              Create Template
            </Button>
          }
          readMoreUrl={READ_MORE_LINKS.TEMPLATE}
        />
      )}
      enabled={() => (
        <Link to={MANAGE_TEMPLATES_CREATE}>
          <Button className="min-w-[134px] cursor-pointer" filled primary size="s">
            Create Template
          </Button>
        </Link>
      )}
      entitlementCheck={{ featureName: ENTITLEMENT_NAME.ANNOTATION_TEMPLATE }}
      loading={() => (
        <Button className="min-w-[134px]" disabled filled primary size="s">
          Create Template
        </Button>
      )}
    />
  );
  const displayedRoles = table.getRowModel().rows.length;

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          confirmationModalState={confirmationModalState}
          setConfirmationModalState={setConfirmationModalState}
        />
      )}
      <SideBar />
      <div className={cn('max-h-fit', 'w-full', 'px-6', 'overflow-auto', 'flex flex-col')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'justify-between',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800')}>Account-Level Annotation Templates</h1>

          {createTemplate}
        </div>

        <div className="mt-3 flex items-start justify-between">
          <p className="truncate color-neutral-800 typo-text-s">
            Create custom fields and templates for use in Annotations.
          </p>

          <div className="flex items-center justify-center gap-2">
            {globalFilter && (
              <div className="max w-360px">
                <SearchResultsSummary dataLength={displayedRoles} searchKey={globalFilter} />
              </div>
            )}
            <div className={cn('w-360px', !templates.length && 'opacity-50')}>
              <Input
                disabled={!templates.length}
                onChange={e => handleSearchInputField(e, setGlobalFilter)}
                placeholder="Search for templates"
                tail={<FindIcon />}
                value={globalFilter}
              />
            </div>
          </div>
        </div>

        <Table
          className="mb-8 mt-6"
          empty={
            <EmptyListComponent
              searchTerm={globalFilter}
              searchTopic={EmptyListSeachTopic.TEMPLATES}
            >
              {createTemplate}
            </EmptyListComponent>
          }
          isLoading={isFetching}
          table={table}
        />
      </div>
    </div>
  );
};
