import { SideBar } from '@/components/SideBar';
import { DEFAULT_COLOR } from '@/constants/branding';
import { queryClient } from '@/graphql/client';
import { request } from '@/graphql/request';
import { useAccountV2 } from '@/hooks/useAccountV2';
import { cn } from '@/utils/classname';
import { ADVANCED_UPLOADER } from '@/utils/split';
import { ColorPicker } from '@skand/ui';
import { QueryClientProvider, RequestContextProvider } from '@skand/uploader';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect, useState } from 'react';
import { ChangeReportLogo } from './ChangeReportLogo';
import { ChangeReportLogoWithLegacyUploader } from './ChangeReportLogoWithLegacyUploader';
import { ChangeWebappBanner } from './ChangeWebappBanner';
import { ChangeWebappBannerWithLegacyUploader } from './ChangeWebappBannerWithLegacyUploader';
import { ChangeButton } from './EntitlementButtons';
import { useUpdateAccountBranding } from './useUpdateAccountBranding';

export const BrandingPage = () => {
  const { account } = useAccountV2();

  const { mutate: updateAccountBranding } = useUpdateAccountBranding();

  const [color, setColor] = useState(DEFAULT_COLOR);
  const [openColorPicker, setOpenColorPicker] = useState(false);

  const treatments = useTreatments([ADVANCED_UPLOADER]);
  const isAdvancedUploaderEnabled = treatments[ADVANCED_UPLOADER].treatment === 'on';

  useEffect(() => {
    if (account?.branding?.color) {
      setColor(account?.branding?.color);
    }
  }, [account?.branding?.color]);

  const handleCloseMenu = () => {
    if (account?.branding?.color) {
      setColor(account?.branding?.color);
    }
    setOpenColorPicker(false);
  };

  const handleSaveColor = (color: string) => {
    if (color !== account?.branding?.color) {
      setColor(color);
      updateAccountBranding({ color });
    }
    setOpenColorPicker(false);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      {openColorPicker && (
        <ColorPicker
          closeMenu={handleCloseMenu}
          color={color}
          colorPickerHeader="Custom colour"
          onSaveColorButton={handleSaveColor}
          openingCoordinates={{ x: 420, y: 500 }}
          setColor={color => setColor(color.hex)}
        />
      )}
      <div className={cn('max-h-fit', 'w-full', 'px-6', 'overflow-auto', 'pb-8')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'justify-between',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800')}>Branding</h1>
        </div>

        <div className="mt-3 flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">
            Customize web-app, report, and share link logos.
          </p>
        </div>

        <div className="flex">
          <div>
            <p className="mt-8 color-neutral-800 typo-text-medium">Web-app banner</p>
            <p className="mt-3 color-neutral-600 typo-text-small">
              The banner will be used at the top of the web-app. Recommended image size: 40px height
            </p>

            <div className="mt-3">
              {isAdvancedUploaderEnabled ? (
                <ChangeWebappBanner />
              ) : (
                <RequestContextProvider value={request}>
                  <QueryClientProvider client={queryClient}>
                    <ChangeWebappBannerWithLegacyUploader />
                  </QueryClientProvider>
                </RequestContextProvider>
              )}
            </div>

            <div>
              <p className="mt-8 color-neutral-800 typo-text-medium">Brand colour</p>
              <p className="mt-3 color-neutral-600 typo-text-small">
                A small strip of your brand colour will be visible above the banner.
              </p>

              <div className="mt-3 w-160px flex flex-col items-center overflow-hidden b-1 b-neutral-400 rounded-1 b-solid">
                <div
                  className={cn('h-160px w-160px text-neutral-800 typo-text-small')}
                  style={{ backgroundColor: color }}
                />
                <p className="color-neutral-800 typo-text-small">{color.toLocaleUpperCase()}</p>
              </div>

              <div className="mt-3 w-160px flex gap-3">
                <ChangeButton onClick={() => setOpenColorPicker(!openColorPicker)} />
              </div>
            </div>

            <div>
              <p className="mt-8 color-neutral-800 typo-text-medium">Report Logo</p>
              <p className="mt-3 color-neutral-600 typo-text-small">
                Your uploaded logo will be used in the exported reports. Recommended image size: 160
                x 90px.
              </p>
            </div>

            <div className="mt-3">
              {isAdvancedUploaderEnabled ? (
                <ChangeReportLogo />
              ) : (
                <RequestContextProvider value={request}>
                  <QueryClientProvider client={queryClient}>
                    <ChangeReportLogoWithLegacyUploader />
                  </QueryClientProvider>
                </RequestContextProvider>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
