import {
  ABORT_MULTIPART_UPLOAD,
  COMPLETE_MULTIPART_UPLOAD,
  CREATE_FILE,
  CREATE_MULTIPART_UPLOAD,
  CREATE_PRESIGNED_UPLOAD_PART_URL,
} from '@/graphql/mutations';
import { request } from '@/graphql/request';
import {
  AwsMultipartRequestService,
  AwsMultipartUploaderFactory,
  ServerError,
  SimpleUploadSessionRequestService,
  UNIT,
} from '@skand/uploader';

export const simpleUploadSessionRequestService = new SimpleUploadSessionRequestService({
  createFile: async (variables, signal) => {
    const response = await request({ document: CREATE_FILE, variables, signal });
    const data = response.createFile?.id;
    if (!data) throw new ServerError('server responded unexpected data');
    return data;
  },
});

export const awsMultipartRequestService = new AwsMultipartRequestService({
  abortMultipartUpload: async variables => {
    const response = await request({ document: ABORT_MULTIPART_UPLOAD, variables });
    const data = response.abortMultipartUpload;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },

  completeMultipartUpload: async (variables, signal) => {
    const response = await request({ document: COMPLETE_MULTIPART_UPLOAD, variables, signal });
    const data = response.completeMultipartUpload;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },

  createMultipartUpload: async (fileNode, signal) => {
    const response = await request({
      document: CREATE_MULTIPART_UPLOAD,
      variables: {
        fileName: fileNode.file.name,
      },
      signal,
    });
    const data = response.createMultipartUpload;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    const { bucket, key, uploadId, fileId } = data;
    if (!bucket || !fileId || !key || !uploadId)
      throw new ServerError('server responded unexpected data');
    return { bucket, fileId, key, uploadId };
  },

  signMultipartUploadPart: async (variables, signal) => {
    const response = await request({
      document: CREATE_PRESIGNED_UPLOAD_PART_URL,
      variables,
      signal,
    });
    const data = response.createPresignedUploadPartUrl;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },
});

export const uploaderFactory = new AwsMultipartUploaderFactory(
  500 * UNIT.MB,
  awsMultipartRequestService,
);
